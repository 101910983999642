/***************************************************
  Imports
***************************************************/

import React from "react"

import { Link } from "gatsby"

import { useRecoilState } from "recoil"
import { menuState } from "../state"

import logoImg from "../images/logo.svg"
import youtubeImg from "../images/social-youtube.svg"
import linkedInImg from "../images/social-linkedin.svg"
import twitterImg from "../images/social-twitter.svg"

/***************************************************
  Component
***************************************************/

export default () => {
  const [isMenuOpen, toggleMenu] = useRecoilState(menuState)

  return (
    <footer className="section-footer">
      <div className="col-4 span-5">
        <h6 className="color-white">Sitemap</h6>
        <div className="links">
          <Link className="gray small" to="/">
            Home
          </Link>
          <Link className="gray small" to="/#products">
            Products
          </Link>
          <Link
            className="gray small"
            to="/microsoft-dynamics-365-business-central"
          >
            Microsoft Dynamics 365 Business Central
          </Link>
          <Link className="gray small" to="/#industries">
            Industries
          </Link>
          <Link className="gray small" to="/#simcrest-apps">
            {" "}
            SimCrest Apps
          </Link>
          <Link className="gray small" to="/#services">
            Services
          </Link>
          <Link className="gray small" to="/methodologies">
            Methodologies
          </Link>
          <Link className="gray small" to="/about-us">
            About Us
          </Link>
          <Link className="gray small" to="/contactus">
            Contact Us
          </Link>
        </div>
        <div className="copyright">
          <span className="color-white xsmall">
            © 2001-2021 SimCrest, Inc. All rights reserved.
          </span>
        </div>
      </div>

      <div className="col-11 span-3">
        <h6 className="color-white">Office</h6>

        <p className="color-gray small">
          1914 Skillman Street, Suite 110-319
          <br />
          Dallas, Texas 75206
          <br />
          P: (214) 644-4000
        </p>
        <p className="color-gray small">
          1749 NE Miami Ct, Suite 609
          <br />
          Miami, Florida 33132
          <br />
          P: (305) 767-2370
        </p>
        <p className="color-gray small">
          3415 South Sepulveda Blvd., Suite 1100
          <br />
          Los Angeles, California 90034
          <br />
          P: (310) 873-3220
        </p>

        <div className="social-container">
          <img className="logo" src={logoImg} alt="Logo" />
          <span className="mobile-copyright color-gray">
            © 2001-2021 SimCrest, Inc. All rights reserved.
          </span>
          <div className="social-links">
            <a
              href="https://www.youtube.com/channel/UCr_gxwgGhB69pVWEdNTvelg"
              target="_blank"
            >
              <img src={youtubeImg} alt="YouTube" />
            </a>
            <a
              href="https://www.linkedin.com/company/simcrest-inc."
              target="_blank"
            >
              <img src={linkedInImg} alt="LinkedIn" />
            </a>
            <a href="https://twitter.com/simcrest" target="_blank">
              <img src={twitterImg} alt="Twitter" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
